import React from 'react';
import ProfileNav from '../CaptainProfileEdit/ProfileNav/ProfileNav';
import ellipse from './Images/Ellipse2.png';
import Footer from '../Footer/Footer';
import history from '../../history';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { auth } from '../../firebase/firebase';
import { userProfile } from '../../actions';
import userPic from './Images/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Conversation extends React.Component {
  state = {
    general: true,
    photos: false,
    message: [],
    userProfile: '',
    reply: '',
    writeError: '',
  };

  groupBy = (payload, keys) => {
    return payload.reduce((prev, curr) => {
      const value = keys.map(key => curr[key]).join('-');
      if (!prev[value]) {
        prev[value] = [curr];
      } else {
        prev[value] = [...prev[value], curr];
      }
      return prev;
    }, {});
  };

  componentDidMount() {
    const fetchData = () => {
      this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
        if (!userAuth) return;

        const db = firebase.database();
        const firestore = firebase.firestore();

        let messages = [];

        const fetchMessages = async (uid, userType) => {
          const collectionRef = db.ref('messages').orderByChild(userType);
          const ref = collectionRef.equalTo(uid);

          return new Promise((resolve, reject) => {
            try {
              // Change 'on' to 'once' to prevent multiple listeners
              ref.once('value', async (snapshot) => {
                const snapShotValues = Object.values(snapshot.val() || {});

                if (!snapshot.val()) {
                  resolve([]);
                } else {
                  const promises = snapShotValues.map(async (snap) => {
                    const uid = snap.customerUID !== userAuth.uid ? snap.customerUID : snap.boatOwnerUID;
                    const userRef = firestore.collection('users').doc(uid);

                    try {
                      const userDoc = await userRef.get();
                      const userData = userDoc.exists ? userDoc.data() : {};
                      const userProfile = {
                        ...userData,
                        email: userData.email || '',
                        displayName: userData.displayName || '',
                        firstName: userData.firstName || '',
                        lastName: userData.lastName || ''
                      };

                      console.log('Fetched userProfile:', userProfile);

                      return { ...snap, userProfile };
                    } catch (err) {
                      console.log('Error getting document:', err);
                      reject(err);
                    }
                  });
                  const placeCells = await Promise.all(promises);
                  resolve(placeCells);
                }
              });
            } catch (err) {
              reject(err);
            }
          });
        };

        Promise.all([
          fetchMessages(userAuth.uid, 'boatOwnerUID'),
          fetchMessages(userAuth.uid, 'customerUID')
        ]).then(async (response) => {
          const flatten = (arr) => arr.reduce((acc, val) => acc.concat(val), []);
          const flattedMessage = flatten(response);

          messages = this.groupBy(flattedMessage, ['boatOwnerUID', 'customerUID']);

          // Sort messages by timestamp in descending order
          Object.keys(messages).forEach(key => {
            messages[key].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          });

          this.setState({ messages, loading: false });
        });
      });
    };

    fetchData();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.conversation && props.conversation.length > 0) {
      const userProfile = props.conversation[0].userProfile || {};
      console.log('Setting userProfile from props:', userProfile);
      
      // Fetch fresh user data to ensure we have the email
      const fetchUserProfile = async () => {
        try {
          const db = firebase.firestore();
          const uid = props.conversation[0].customerUID !== props.currentUser.id 
            ? props.conversation[0].customerUID 
            : props.conversation[0].boatOwnerUID;
          
          const userDoc = await db.collection('users').doc(uid).get();
          const userData = userDoc.exists ? userDoc.data() : {};
          
          const updatedUserProfile = {
            ...userProfile,
            email: userData.email || userProfile.email || '',
            displayName: userData.displayName || userProfile.displayName || '',
            firstName: userData.firstName || userProfile.firstName || '',
            lastName: userData.lastName || userProfile.lastName || ''
          };

          console.log('Updated userProfile with email:', updatedUserProfile);
          
          this.setState({ 
            message: props.conversation,
            userProfile: updatedUserProfile
          });
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };

      fetchUserProfile();
    }
  }

  componentWillUnmount() {
    if (this.props.conversation.length < 1) {
      history.push('/message');
    }
    if (this.unsubscribeFromAuth) {
      this.unsubscribeFromAuth();
    }
  }

  handleClick = (name, value) => {
    if (name === 'general') {
      this.setState({ general: true });
      this.setState({ photos: false });
      this.setState({ boatingQualification: false });
    }
    if (name === 'photos') {
      this.setState({ general: false });
      this.setState({ photos: value });
      this.setState({ boatingQualification: false });
    }
  };

  handleSubmit = async () => {
    const db = firebase.database();
    const newMessage = {
      message: this.state.reply.message,
      boatOwnerUID: this.props.conversation[0].boatOwnerUID,
      customerUID: this.props.conversation[0].customerUID,
      currentMessageUID: this.props.conversation[0].currentMessageUID,
      messageIdentifier: this.props.currentUser.id,
      time: new Date().toTimeString(),
      date: new Date().toDateString(),
      timestamp: new Date(),
    };

    this.setState({
      message: [...this.state.message, newMessage],
    });

    try {
      await db.ref('messages').push(newMessage);
      if (this.state.userProfile && this.state.userProfile.email) {
        this.sendEmailNotification(newMessage);
      } else {
        console.error('Error sending email: No recipients defined');
      }
      this.setState({ reply: '' });
    } catch (error) {
      this.setState({ writeError: error.message });
    }

    toast.success('Sent!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  sendEmailNotification = (message) => {
    const { userProfile } = this.state;
    const { currentUser } = this.props;
    
    console.log('Attempting to send email with currentUser:', currentUser);
    
    if (!userProfile || !userProfile.email) {
      console.error('Error sending email: No recipients defined');
      return;
    }

    const emailDetails = {
      to: userProfile.email,
      name: currentUser.displayName || `${currentUser.firstName} ${currentUser.lastName}`, // Changed to use sender's name
      boatManufacturer: this.props.conversation[0].boatManufacturer,
      boatModel: this.props.conversation[0].boatModel,
      message: message.message,
    };

    console.log('Sending email with details:', emailDetails);

    fetch('https://us-central1-boatrbo-6a8a6.cloudfunctions.net/sendEmailNotification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailDetails),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Email sent successfully:', data);
      } else {
        console.error('Error sending email:', data.error);
      }
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
  };

  render() {
    const messageArr = this.state.message;
    return (
      <div className="message-container">
        <ProfileNav />
        <div className="message-body">
          <div
            className="personal-profile-flex"
            style={{ marginTop: 0, padding: 0 }}
          >
            <div className="personal-profile-left">
              <p className="personal-profile-text">Message</p>
              <div className="personal-profile-line"></div>
              <p
                className={
                  this.state.general
                    ? 'personal-profile-sub-text-blue'
                    : 'personal-profile-sub-text'
                }
                onClick={() => {
                  this.handleClick('general', true);
                  history.push('/message');
                }}
              >
                Inbox
              </p>
            </div>
            {this.state.general ? (
              <div className="personal-profile-right">
                <div
                  className="personal-profile-right-container"
                  style={{ minHeight: '150px' }}
                >
                  <div className="personal-information-header">
                    <h1 className="personal-information-header-text">
                      {`${
                        this.state.userProfile.displayName
                          ? `Conversation with ${this.state.userProfile.displayName}`
                          : this.state.userProfile.firstName &&
                            this.state.userProfile.lastName
                          ? `Conversation with ${this.state.userProfile.firstName} ${this.state.userProfile.lastName}`
                          : ''
                      }`}
                    </h1>
                  </div>
                  <div className="conversation-body">
                    <div className="conversation-body-left">
                      <div className="conversation-fixed-messages">
                        {messageArr.map((data, index) =>
                          data.messageIdentifier ===
                          this.props.currentUser.id ? (
                            <div
                              className="conversation-body-received"
                              key={index}
                            >
                              <p className="conversation-body-received-text">
                                {data.message}
                              </p>
                              <p
                                className="conversation-body-received-text"
                                style={{ marginTop: '10px' }}
                              >
                                {`${data.date} ${data.time.slice(0, 5)}`}
                              </p>
                            </div>
                          ) : (
                            <div className="conversation-body-sent" key={index}>
                              <p className="conversation-body-received-text">
                                {data.message}
                              </p>
                              <p
                                className="conversation-body-received-text"
                                style={{ marginTop: '10px' }}
                              >
                                {`${data.date} ${data.time.slice(0, 5)}`}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                      <p className="conversation-body-reply-to">
                        {`${
                          this.state.userProfile.displayName
                            ? `Reply to ${this.state.userProfile.displayName}`
                            : this.state.userProfile.firstName &&
                            this.state.userProfile.lastName
                            ? `Reply to ${this.state.userProfile.firstName} ${this.state.userProfile.lastName}`
                            : ''
                        }`}
                      </p>
                      <textarea
                        className="conversation-body-reply-input"
                        onChange={(event) =>
                          this.setState({
                            reply: {
                              message: event.target.value,
                              boatOwnerUID: this.props.conversation[0]
                                .boatOwnerUID,
                              customerUID: this.props.conversation[0]
                                .customerUID,
                              currentMessageUID: this.props.conversation[0]
                                .currentMessageUID,
                              messageIdentifier: this.props.currentUser.id,
                              time: new Date().toTimeString(),
                              date: new Date().toDateString(),
                              timestamp: new Date(),
                            },
                          })
                        }
                      />
                      <button
                        className="conversation-body-reply-send"
                        onClick={this.handleSubmit}
                      >
                        Send
                      </button>
                      <ToastContainer />
                    </div>
                    <div className="conversation-body-right">
                      <div className="conversation-body-trips">
                        <p className="conversation-body-trips-text">About</p>
                      </div>
                      <div className="conversation-body-pic">
                        <img
                        alt="convo"
                          src={
                            this.state.userProfile.profilePic
                              ? this.state.userProfile.profilePic
                              : userPic
                          }
                          style={{
                            width: '117px',
                            height: '117px',
                            borderRadius: '100px',
                          }}
                          className="conversation-body-name"
                        />
                      </div>
                      <p className="conversation-body-name">{`${
                        this.state.userProfile.displayName
                          ? `${this.state.userProfile.displayName}`
                          : this.state.userProfile.firstName &&
                          this.state.userProfile.lastName
                          ? `${this.state.userProfile.firstName} ${this.state.userProfile.lastName}`
                          : ''
                      }`}</p>
                      {this.state.userProfile.location ? (
                        <div
                          className="conversation-body-name-location"
                          style={{ marginTop: '57px' }}
                        >
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text">
                              Location
                            </p>
                          </div>
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text-right">
                            {
                                this.state.userProfile.location
                                ? this.state.userProfile.location
                                : "unknown"
                              }
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.userProfile.language ? (
                        <div className="conversation-body-name-location">
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text">
                              Language
                            </p>
                          </div>
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text-right">
                              {
                                this.state.userProfile.language
                                ? this.state.userProfile.language
                                : "unknown"
                              }
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.userProfile.responseTime ? (
                        <div className="conversation-body-name-location">
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text">
                              Response time
                            </p>
                          </div>
                          <div className="conversation-body-name-location-left">
                            <p className="conversation-body-name-location-text-right">
                              1hr
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {this.state.photos ? (
              <div className="personal-profile-right">
                <div
                  className="personal-profile-right-container"
                  style={{ minHeight: '200px' }}
                >
                  <div className="personal-information-header">
                    <h1 className="personal-information-header-text">Sent</h1>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    conversation: state.conversation,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(Conversation);
