import React from 'react';

class Pagination extends React.Component {
  handlePrev = () => {
    const { currentPage, handlePrevButton } = this.props;
    if (currentPage > 1) {
      handlePrevButton();
      window.scrollTo(0, 0);
    }
  };

  handleNext = () => {
    const { currentPage, totalBoats, boatsPerPage, handleNextButton } = this.props;
    if (currentPage < Math.ceil(totalBoats / boatsPerPage)) {
      handleNextButton();
      window.scrollTo(0, 0);
    }
  };

  handlePageClick = (number) => {
    const { paginate } = this.props;
    paginate(number);
    window.scrollTo(0, 0);
  };

  render() {
    const {
      boatsPerPage,
      totalBoats,
      currentPage,
    } = this.props;
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalBoats / boatsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="boat-result-pagination-container">
        <nav className="boat-result-pagination-align">
          <ul
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              paddingLeft: '0px',
            }}
          >
            <li
              className="boat-result-pagination-page-number"
              style={{ width: '87px' }}
              onClick={this.handlePrev}
            >
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '27px',
                  color: '#787878',
                }}
              >
                Prev
              </div>
            </li>
            {pageNumbers.map((number) => (
              <li
                className={
                  currentPage === number
                    ? 'boat-result-pagination-page-number blue'
                    : 'boat-result-pagination-page-number'
                }
                key={number}
                onClick={() => this.handlePageClick(number)}
              >
                <div
                  style={
                    currentPage === number
                      ? {
                          fontStyle: 'normal',
                          fontWeight: '500',
                          fontSize: '18px',
                          lineHeight: '27px',
                          color: '#ffffff',
                        }
                      : {
                          fontStyle: 'normal',
                          fontWeight: '500',
                          fontSize: '18px',
                          lineHeight: '27px',
                          color: '#787878',
                        }
                  }
                >
                  {number}
                </div>
              </li>
            ))}
            <li
              className="boat-result-pagination-page-number"
              style={{ width: '87px' }}
              onClick={this.handleNext}
            >
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '27px',
                  color: '#787878',
                }}
              >
                Next
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Pagination;
