import React from 'react';
import ProfileNav from '../ProfileNav/ProfileNav';
import Footer from '../../Footer/Footer';
import { auth } from '../../../firebase/firebase';
import './passwordReset.css';

class PasswordReset extends React.Component {
  state = {
    email: '',
    message: '',
    error: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handlePasswordReset = async () => {
    const { email } = this.state;
    if (email) {
      try {
        await auth.sendPasswordResetEmail(email);
        this.setState({ message: 'Password reset email sent!', error: '' });
      } catch (error) {
        console.error('Error sending password reset email:', error);
        this.setState({ error: 'Error sending password reset email.', message: '' });
      }
    } else {
      this.setState({ error: 'Please enter your email address.', message: '' });
    }
  };

  render() {
    const { email, message, error } = this.state;
    return (
      <div className="captain-profile-edit-container">
        <ProfileNav />
        <div className="personal-profile-flex">
          <div className="personal-profile-left">
            <p className="personal-profile-text">Settings</p>
            <div className="personal-profile-line"></div>
            <p className="personal-profile-sub-text" style={{ color: '#39A0ED' }}>
              Reset Password
            </p>
          </div>
          <div className="personal-profile-right-container" style={{ minHeight: '200px', width: '70%' }}>
            <div className="personal-information-header">
              <h1 className="personal-information-header-text">Reset Password</h1>
            </div>
            <div className="personal-information-body" style={{ padding: '27px 5%', minHeight: '100px', position: 'relative' }}>
              <p className="contactUs-content-location-address" style={{ marginTop: '12px' }}>
                Enter your email address to receive a password reset link.
              </p>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                className="password-reset-input"
              />
              <button onClick={this.handlePasswordReset} className="password-reset-button">
                Send Password Reset Email
              </button>
              {message && <p className="password-reset-message">{message}</p>}
              {error && <p className="password-reset-error">{error}</p>}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PasswordReset;
