import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { ToastContainer, toast } from 'react-toastify';
import userIcon from './images/logo_sm.png';

class MessageOwner extends React.Component {
  state = {
    personalProfile: '',
    message: '',
    time: new Date().toTimeString(),
    date: new Date().toDateString(),
    timestamp: new Date(),
    boatManufacturer: this.props.selectBoat.boatManufacturer,
    boatModel: this.props.selectBoat.boatModel,
    boatType: this.props.selectBoat.boatType,
    boatOwnerEmail: '',
    boatOwnerUID: this.props.selectBoat.uid,    
    customerUID: this.props.currentUser.id,
    currentMessageUID: this.props.currentUser.id,
    messageIdentifier: this.props.currentUser.id,
    displayName: this.props.currentUser.displayName,
  };

  componentDidMount() {
    this.fetchBoatOwnerEmail();
    this.fetchDisplayName();
    // console.log('MessageOwner props:', this.props);
  }

  fetchBoatOwnerEmail = async () => {
    const { boatOwnerUID } = this.props;
    const db = firebase.firestore();
    try {
      const ownerDoc = await db.collection('users').doc(boatOwnerUID).get();
      if (ownerDoc.exists) {
        this.setState({ boatOwnerEmail: ownerDoc.data().email });
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching boat owner email:', error);
    }
  };

  fetchDisplayName = async () => {
    const { boatOwnerUID } = this.props;
    console.log('Fetching displayName for boatOwnerUID:', boatOwnerUID);
    if (!boatOwnerUID) {
      console.error('boatOwnerUID is undefined');
      return;
    }
    const db = firebase.firestore();
    try {
      const ownerDoc = await db.collection('users').doc(boatOwnerUID).get(); // Correct document path
      if (ownerDoc.exists) {
        this.setState({ displayName: ownerDoc.data().displayName });
        console.log('Fetched displayName:', ownerDoc.data().displayName); // Log fetched displayName
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching displayName:', error);
    }
  };

  handleSubmit = async () => {
    const db = firebase.database();
    if (this.state.message) {
      try {
        await db.ref('messages').push(this.state);
        this.sendEmailNotification();
      } catch (error) {
        this.setState({ writeError: error.message });
      }
      toast.success('Message Sent', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  sendEmailNotification = () => {
    const emailDetails = {
      to: this.state.boatOwnerEmail,
      name: this.state.displayName,
      boatManufacturer: this.state.boatManufacturer,
      boatModel: this.state.boatModel,
      message: this.state.message,
    };

    console.log('Sending email with details:', emailDetails);

    fetch('https://us-central1-boatrbo-6a8a6.cloudfunctions.net/sendEmailNotification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailDetails),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Email sent successfully:', data);
      } else {
        console.error('Error sending email:', data.error);
      }
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
  };

  render() {
    console.log('state===>', this.state);
    console.log('time===>', this.state.time);
    console.log('MessageOwner props:', this.props);
    return (
      <div className="select-boat-message-owner-container">
        <div className="select-boat-message-owner-container-left">
          <div className="select-boat-message-owner-container-avatar">

          <img
                    src={
                      this.state.personalProfile
                        ? this.state.personalProfile.images
                        : userIcon
                    }
                    alt="profile"
                    className="dashboard-ash-profile-pic-img"
                  />
                  
          </div>
          <div className="select-boat-message-owner-container-name">

            {this.props.selectBoat.boatType}
          
          </div>
          <div className="select-boat-message-owner-container-city-and-age">
            <p style={{ display: 'inline' }}>
              
              {this.props.selectBoat.boatManufacturer}        
            
            </p>
            <p>
              
              {this.props.selectBoat.boatModel}

            </p>
          </div>
        </div>
        <div className="select-boat-message-owner-container-right">
          <h1 className="select-boat-message-owner-enquiry">Your Enquiry</h1>
          <div className="select-boat-message-owner-departure">
            <div>
              <h3 className="select-boat-message-owner-departure-text">
                Departure Date
              </h3>
              <p className="select-boat-message-owner-departure-text-date">
                {this.props.bookingDetails.fromDate}
              </p>
            </div>

            <div>
              <h3 className="select-boat-message-owner-departure-text">
                Duration
              </h3>
              <p className="select-boat-message-owner-departure-text-date">
                {this.props.bookingDetails.bookingDays}
              </p>
            </div>

            <div>
              <h3 className="select-boat-message-owner-departure-text">
                Price
              </h3>
              <p className="select-boat-message-owner-departure-text-date">
                {this.props.bookingDetails.price}
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: '17px', width: '80%', display: 'inline-block' }}
          >
            <p className="select-boat-message-owner-explain-text">
              Please explain your enquiry here, and the captain will respond to
              you asap
            </p>
          </div>
          <div className="select-boat-message-owner-input-container">
            <textarea
              className="select-boat-message-owner-input-name"
              type="text"
              placeholder="Enter your message here"
              style={{ height: '135px', paddingTop: '12px' }}
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
            />
          </div>
          <div className="select-boat-message-owner-button-container">
            <div className="select-boat-message-owner-button-align">
              <button
                className="select-boat-message-owner-cancel"
                onClick={this.props.messageCloseModal}
              >
                Cancel
              </button>
              <button
                className="select-boat-message-owner-send"
                onClick={this.handleSubmit}
              >
                Send
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookingDetails: state.bookingCard,
    selectBoat: state.selectBoat,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(MessageOwner);
