import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MessagingBanner.css';

const MessagingBanner = ({ type, message }) => {
  const [visible, setVisible] = useState(true);

  const getBannerClass = () => {
    switch (type) {
      case 'success':
        return 'banner-success';
      case 'error':
        return 'banner-error';
      case 'info':
        return 'banner-info';
      default:
        return '';
    }
  };

  if (!visible) return null;

  return (
    <span className={`messaging-banner ${getBannerClass()}`}>
      <span>{message}</span>
      <button className="close-button" onClick={() => setVisible(false)}>×</button>
    </span>
  );
};

MessagingBanner.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default MessagingBanner;
